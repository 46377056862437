import { Stack, Typography } from '@mui/material'
import { Customer } from 'api/customer'
import { Profile, ProfilePosition } from 'api/profile'
import { useAuthContext } from 'AuthContext'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useMainRoutes } from 'routes'

export const checkCompletenessProfile = (p: unknown): p is Profile => {
  return typeof p === 'object' &&
    p !== null &&
    'email' in p &&
    'firstName' in p &&
    'familyName' in p &&
    typeof p.email === 'string' &&
    typeof p.familyName === 'string' &&
    typeof p.firstName === 'string'
}

export interface CheckPosition {
  isEmpty?: boolean
  willExpired?: ProfilePosition[]
  expired?: ProfilePosition[]
  emptyPositions?: ProfilePosition[]
}

export const checkPosition = ({ positions = [] }: Profile, currentCustomer?: Customer) => {
  if (positions.length === 0) {
    return {}
  }

  const fp = currentCustomer ? positions.filter(item => item.customer.id === currentCustomer.id) : positions

  const isEmpty = positions.filter(item => item.isEmpty).length === positions.length
  const emptyPositions = fp.filter(item => item.isEmpty)
  const willExpired = fp.filter(item => item.willExpired)
  const expired = fp.filter(item => item.expired)

  return { isEmpty, willExpired, expired, emptyPositions }
}

export interface Params {
  currentCustomer?: Customer
}
export default function DispatcherProfileCompletenessAlert ({ currentCustomer }: Params) {
  const { links } = useMainRoutes()

  const { profile } = useAuthContext()
  const [emptyProfile, setEmptyProfile] = useState(false)
  const [emptyAllPositions, setEmptyAllPositions] = useState(false)
  const [emptyPositions, setEmptyPositions] = useState<string[]>()
  const [willExpired, setWillExpired] = useState<string>()
  const [expired, setExpired] = useState<string>()

  const show = emptyProfile || !!emptyPositions || !!willExpired || !!expired || emptyAllPositions
  const onlyEmpty = emptyProfile || emptyAllPositions

  useEffect(() => {
    if (profile === undefined) {
      return
    }

    const isEmpty = !checkCompletenessProfile(profile)

    setEmptyProfile(isEmpty)

    const { isEmpty: emptyAllPositions = false, willExpired = [], expired = [], emptyPositions = [] } = checkPosition(profile, currentCustomer)

    const emptyPositionsNames = emptyPositions.map(item => item.customer.shortName || item.customer.fullName)
    setEmptyPositions(emptyPositionsNames.length > 0 ? emptyPositionsNames : undefined)

    setEmptyAllPositions(emptyAllPositions)
    setWillExpired(willExpired.map(item => item.customer.shortName || item.customer.fullName).join(', '))
    setExpired(expired.map(item => item.customer.shortName || item.customer.fullName).join(', '))
  }, [profile, currentCustomer])

  if (profile === undefined || !show) {
    return (<></>)
  }

  return (<>
    <Stack
      bgcolor='#ee6a5f'
      p={1}
    >
      <Stack direction='row' gap={1} justifyContent='center'>
        { onlyEmpty
          ? emptyProfile
            ? <Typography fontWeight={500}>Для подписания документов необходимо заполнить профиль пользователя!</Typography>
            : (!emptyProfile && emptyAllPositions) && <Typography fontWeight={500}>Заполните информацию о должностях в компаниях заказчиков!</Typography>
          : expired
            ? <Typography fontWeight={500} >Истек срок действия доверенности для {expired}!</Typography>
            : (!expired && willExpired)
                ? <Typography fontWeight={500} >Истекает срок действия доверенности для {willExpired}!</Typography>
                : emptyPositions
                  ? emptyPositions.length > 1
                    ? <Typography fontWeight={500}>Заполните информацию о должностях в компаниях заказчиков {emptyPositions.join(', ')}!</Typography>
                    : <Typography fontWeight={500}>Заполните информацию о должности в компании заказчика {emptyPositions[0]}!</Typography>
                  : <></>
        }
      <NavLink to={links.PROFILE_PAGE} style={{ color: '#111111', fontWeight: 200 }}>Перейти в профиль</NavLink>
      </Stack>
    </Stack>
  </>)
}
