import './styles.sass'
import { Id, isId } from 'api/Id'
import { driverCreate, VehicleTrailer, DriverForUpdate, driverGet, DriverStatus, driverUpdate, PassportForDriverCreate } from 'api/driver'
import { useAuthContext } from 'AuthContext'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useValidate from 'validation/validate'
import schema from 'validation/Driver'
import { useMainRoutes } from 'routes'
import NavigatePanel from 'ui/NavigatePanel'
import { Button, Stack, Typography, Box } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import InfoCard from 'ui/InfoCard'
import TextField from 'ui/TextField'
import { personName } from 'util/personName'
import AntSwitch from 'ui/AntSwitch'
import Upload from 'ui/Upload'
import formatDate from 'util/FormatDate'
import Modal from 'ui/Modal'
import { phoneClean, PHONE_MASK } from 'common/Mask/PhoneMask'
import DatePicker from 'ui/DatePicker'
import metaPassport from 'entity/Passport'
import schemaPassport, { clear as clearPassportData } from 'validation/Passport'
import dayjs from 'dayjs'
import { ChipDriverStatus } from 'common/ChipDriverStatus'
import { BlackListEntry } from 'api/black-list'
import { vehicleList, Vehicle, VehicleStatus } from 'api/vehicle'
import { trailerList, Trailer, TrailerStatus } from 'api/trailer'
import Select from 'ui/Select'
import SquareButton from 'ui/SquareButton'
import { DeleteForever } from '@mui/icons-material'
import objectHashOrgin from 'util/objectHash'
import allTrue from 'util/allTrue'
import { EmailMask } from 'common/Mask/EmailMask'
import { PASSPORT_DEPARTAMENT_CODE_MASK } from 'common/Mask/Passport'
import onlyFields from 'util/onlyFields'
import DownloadZipButton, { slug } from 'ui/DownloadZipButton'
import driverPack, { Result as Pack } from 'common/map/driverPack'
import vehicleName from 'util/vehicleName'
import trailerName from 'util/trailerName'
import AddressSuggestionsInput, { AddressData, getAddressDetails } from 'ui/AddressSuggestionsInput'

type Passport = Partial<PassportForDriverCreate>

const objectHash = (o: Object | undefined) => o !== undefined ? objectHashOrgin(o) : undefined

type UpdateParams = Omit<DriverForUpdate, 'id'>

interface DataMisc {
status: DriverStatus
archiveDate?: number
archiveComment?: string
  truckRuns?: Id[]
  blackList?: BlackListEntry[]
  vehicleTrailer?: VehicleTrailer[]
}

type VehicleTrailerOptions = Partial<VehicleTrailer> & {
  onSelectVehicle: (vehicleId: Id) => void
  onSelectTrailer?: (trailerId?: Id) => void
  onDelete?: () => void
  vehicles: Vehicle[]
  trailers: Trailer[]
  disabled?: boolean
}

function VehicleTrailerRow ({
  vehicleId,
  trailerId,
  onSelectVehicle,
  onSelectTrailer,
  onDelete,
  vehicles,
  trailers,
  disabled = false
}: VehicleTrailerOptions) {
  return <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
    <Select
      label='ТС'
      placeholder='Выберите ТС'
      options={vehicles.map(({ id, brand, model, ...doc }) => ({ value: id, name: `${vehicleName(doc, 'short')} - ${brand} ${model}` }))}
      value={ vehicleId }
      onChange={onSelectVehicle}
      disableClearable={true}
      disabled={disabled}
      width='50%'
    />
    { onSelectTrailer && <Select
      label='Прицеп'
      placeholder='Без прицепа'
      options={trailers.map(({ id, model, ...doc }) => ({ value: id, name: `${trailerName(doc)} - ${model}` }))}
      value={ trailerId }
      onChange={onSelectTrailer}
      disabled={disabled}
      width='50%'
    />}
    { !disabled && onDelete && <SquareButton
      variant='contained'
      color='error'
      widthHeight='40px'
      onClick={onDelete}
      style={{ marginTop: '8px' }}
    >
      <DeleteForever style={{ color: '#ffffff' }}/>
    </SquareButton>}
  </Stack>
}

export default function CarrierDriver () {
  const { id } = useParams()

  const [data, setData] = useState<UpdateParams>()
  const [vehicles, setVehicles] = useState<Vehicle[]>([])
  const [trailers, setTrailers] = useState<Trailer[]>([])
  const [disabled, setDisabled] = useState<boolean>(false)
  const [vehicleTrailerDisabled, setVehicleTrailerDisabled] = useState<boolean>(false)
  const [dataMisc, setDataMisc] = useState<DataMisc>()
  const [isNew, setIsNew] = useState(true)
  const [isOfficial, setIsOfficial] = useState<boolean>(true)
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false)
  const [passport, setPassport] = useState<Passport>({})
  const [dataOriginHash, setDataOrginHash] = useState<string>()
  const [passportOriginHash, setPassportOrginHash] = useState<string>()
  const [vehicleTrailerOriginHash, setVehicleTrailerOriginHash] = useState<string>()
  const [doubleError, setDoubleError] = useState(false)
  const [pack, setPack] = useState<Pack>()
  const [registrationAddressData, setRegistrationAddressData] = useState<AddressData>()

  const { profile, phone } = useAuthContext()

  const navigate = useNavigate()
  const { check: checkData, errors: errorsData } = useValidate(schema)
  const { check: checkPassport, errors: errorsPassport } = useValidate(schemaPassport)
  const { links, routesMap } = useMainRoutes()
  const { state } = useLocation()

  const onlyOneDriver = useMemo(() => {
    if (state) {
      return state.onlyOneDriver as boolean
    }

    return false
  }, [state])

  const { handleResponseFailure, handleResponseSuccess } = useAuthContext()

  const clear = (data?: UpdateParams): UpdateParams | undefined => {
    if (data === undefined) {
      return undefined
    }
    const { permitNumber, phone } = data
    return {
      ...data,
      permitNumber: permitNumber === undefined ? undefined : permitNumber.replace(/\D/g, ''),
      phone: phone === undefined ? undefined : phoneClean(phone)
    }
  }

  const save = useCallback(async () => {
    const clearData = clear(data)
    const clearPassport = clearPassportData(passport)

    setDoubleError(false)

    if (!checkData(clearData) || !checkPassport(clearPassport)) {
      return
    }

    if (isId(id)) {
      const resultData = await driverUpdate({
        id,
        ...clearData,
        vehicleTrailer: dataMisc?.vehicleTrailer,
        passport: {
          ...clearPassport,
          registrationAddressDetails: registrationAddressData ? getAddressDetails(registrationAddressData) : undefined
        }
      })

      if (resultData) {
        handleResponseSuccess('Данные водителя изменены')
        navigate(links.CARRIER_DRIVERS_PAGE)
      }
    } else {
      const { success, id, conflicts } = await driverCreate({
        ...clearData,
        vehicleTrailer: dataMisc?.vehicleTrailer,
        passport: {
          ...clearPassport,
          registrationAddressDetails: registrationAddressData ? getAddressDetails(registrationAddressData) : undefined
        },
        isCarrier: onlyOneDriver
      })

      if (!success && conflicts?.double) {
        handleResponseFailure('В системе уже есть водитель с указанным ВУ или номером телефона. Создание профиля невозможно.')
        setConfirmOpen(false)
        setDoubleError(true)
        return
      }

      if (!success && conflicts?.eqPassport) {
        handleResponseFailure('Паспортные данные в профиле перевозчика и паспортные данные водителя не совпадают. Для водителя являющегося перевозчиком паспортные данные должны быть одинаковыми.')
        setConfirmOpen(false)
        setDoubleError(true)
        return
      }

      if (!success && conflicts?.eqFio) {
        handleResponseFailure('ФИО в профиле перевозчика и  водителя не совпадают. Для водителя являющегося перевозчиком ФИО должны быть одинаковыми.')
        setConfirmOpen(false)
        setDoubleError(true)
        return
      }

      if (!success || !isId(id)) {
        handleResponseFailure('Водитель не был создан')
        setConfirmOpen(false)
        return
      }

      if (success) {
        handleResponseSuccess('Водитель создан')
        navigate(links.CARRIER_DRIVERS_PAGE)
      }
    }
  }, [
    data,
    checkData,
    checkPassport,
    passport,
    id,
    dataMisc?.vehicleTrailer,
    handleResponseSuccess,
    handleResponseFailure,
    navigate,
    onlyOneDriver,
    links.CARRIER_DRIVERS_PAGE,
    registrationAddressData
  ])

  const saveVehicleTrailer = useCallback(async () => {
    if (isId(id)) {
      const resultData = await driverUpdate({ id, onlyVehicleTrailer: true, vehicleTrailer: dataMisc?.vehicleTrailer })

      if (resultData) {
        handleResponseSuccess('Данные водителя изменены')
        navigate(links.CARRIER_DRIVERS_PAGE)
      }
    }
  }, [id, dataMisc?.vehicleTrailer, handleResponseSuccess, navigate, links.CARRIER_DRIVERS_PAGE])

  const init = useCallback(async () => {
    vehicleList().then((result) =>
      setVehicles(result.filter(({ status }) => [VehicleStatus.new, VehicleStatus.active].includes(status))))
    trailerList().then((result) =>
      setTrailers(result.filter(({ status }) => status === TrailerStatus.active)))

    if (id === 'add') {
      setData({ consent: onlyOneDriver })
      setDataMisc({ status: DriverStatus.new, vehicleTrailer: [] })
      return
    }

    const result = isId(id) ? await driverGet(id) : null

    if (result !== null) {
      const { id, status, archiveDate, passport, createTs, archiveComment, truckRuns = [], blackList = [], vehicleTrailer = [], isCarrier, ...data } = result
      const onlyChangeableFields = onlyFields(data,
        'familyName', 'firstName', 'secondName',
        'phone', 'email', 'consent', 'permitNumber',
        'scanPermit', 'scanPassport1', 'scanPassport2',
        'scanHireOrder', 'scanContract')
      setDataMisc({ status, archiveDate, archiveComment, truckRuns, blackList, vehicleTrailer })
      setDisabled(status !== DriverStatus.active || truckRuns.length > 0)
      setVehicleTrailerDisabled([DriverStatus.archive, DriverStatus.blocked].includes(status))
      setData(onlyChangeableFields)
      setIsNew(false)
      setDataOrginHash(objectHash(onlyChangeableFields))
      setVehicleTrailerOriginHash(objectHash(vehicleTrailer))

      if (passport !== null) {
        const { id: pId, status: pStatus, registrationAddressDetails, ...passportData } = passport
        setPassport(passportData)
        setPassportOrginHash(objectHash(passportData))
      }
      setPack(driverPack(data))
    } else {
      handleResponseFailure('Водитель не найден')
    }
  }, [handleResponseFailure, id, onlyOneDriver])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { init() }, [])

  const updateString = (key: keyof UpdateParams) => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      const { target: { value } } = event
      setData({ ...data, [key]: value === '' ? undefined : value })
    }
  }

  const updateUpload = (key: string) => {
    return (filename: string | undefined) => {
      setData({ ...data, [key]: filename })
    }
  }

  const handleSave = async () => {
    const clData = clear(data)
    const clPassport = clearPassportData(passport)

    const hashData = objectHash(clData)
    const hashPassport = objectHash(clPassport)
    const hashVehicleTrailer = objectHash(dataMisc?.vehicleTrailer)

    const changedData = dataOriginHash !== hashData
    const changedPassport = passportOriginHash !== hashPassport
    const changedVehicleTrailer = vehicleTrailerOriginHash !== hashVehicleTrailer

    if (allTrue(!changedData, !changedPassport, !changedVehicleTrailer)) {
      return
    }

    if (allTrue(!changedData, !changedPassport, changedVehicleTrailer, !vehicleTrailerDisabled)) {
      await saveVehicleTrailer()
      return
    }

    const chData = checkData(clData)
    const chPassp = checkPassport(clPassport)

    if (chData && chPassp) {
      setConfirmOpen(true)
    }
  }

  const presentation = (data: UpdateParams) => personName(data)

  const updatePassportString = (key: keyof Passport) => (e: ChangeEvent<HTMLInputElement>) => {
    setPassport(prev => ({ ...prev, [key]: e.target.value }))
  }

  const updatePassportDate = (key: keyof Passport) => (value: number) => {
    setPassport(prev => ({ ...prev, [key]: value }))
  }

  const copyProfileData = () => {
    if (profile === undefined || phone === undefined) {
      return
    }

    const { firstName, familyName, secondName, email, passport } = profile

    setData(prev => ({ ...prev, firstName, familyName, secondName, email, phone }))

    if (passport === null) {
      return
    }

    const { status, scan1, scan2, ...pass } = passport
    setPassport(pass)
    setData(prev => ({ ...prev, scanPassport1: scan1, scanPassport2: scan2 }))
  }

  if (data === undefined) {
    return <></>
  }

  return (
    <div className='carrierDriver'>
      <NavigatePanel
        title={isNew
          ? 'Новый водитель'
          : <Box sx={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
              <Box>{presentation(data)}</Box>
              { dataMisc
                ? <ChipDriverStatus status={dataMisc.status} truckRuns={dataMisc.truckRuns}/>
                : <></>
              }
          </Box>
        }
        breadcrumbs={{
          items: [
            { title: isNew ? 'Добавить водителя' : presentation(data) }
          ],
          defaultItems: routesMap.getBreadcrumbs(links.CARRIER_DRIVERS_PAGE)
        }}
        actions={
          <Stack direction='row' spacing={2} justifyContent='end'>
            { pack && <DownloadZipButton pack={pack} archiveName={slug(`водитель_${personName(data)}`)} /> }
            { onlyOneDriver && <Button variant='contained' color='primary' onClick={copyProfileData} >Заполнить данные из профиля</Button> }
            <Button variant='outlined' color='secondary' size='small' onClick={() => navigate(-1)}>
              { disabled ? 'Закрыть' : 'Отменить' }
            </Button>
            { vehicleTrailerDisabled
              ? <></>
              : <Button variant='contained' color='success' size='small' onClick={handleSave} disabled={!isOfficial}>
                Сохранить <SaveIcon sx={{ width: '15px', height: '15px', ml: '10px' }}/>
              </Button>
            }
          </Stack>
        }
      />
      <div className='carrierDriver__body'>
        <div className='carrierDriver__content'>
          {dataMisc?.status === DriverStatus.blocked && <Box sx={{
            mt: '10px',
            background: '#EE6A5F',
            borderRadius: '8px',
            padding: '32px 24px',
            color: '#fff'
          }}>
            <Typography sx={{
              fontWeight: '600',
              fontSize: '18px',
              lineHeight: '22px',
              letterSpacing: '-0.02em',
              marginBottom: '0.5em'
            }}>
              Отказано в добавлении водителя
            </Typography>
            <Typography>
              К сожалению, добавление Водителя с указанными вами данными невозможно добавить в систему.
            </Typography>
            <Typography>
              Дополнительную информацию вы можете получить по адресу <a href='mailto: info@aston.ru' style={{ color: '#fff' }}>info@aston.ru</a>
            </Typography>
          </Box>}
          {dataMisc?.status === DriverStatus.archive && <Box sx={{
            mt: '10px',
            background: '#EBEBEB',
            borderRadius: '8px',
            padding: '32px 24px',
            color: '#111'
          }}>
            <Typography sx={{
              fontWeight: '600',
              fontSize: '18px',
              lineHeight: '22px',
              letterSpacing: '-0.02em'
            }}>
              Водитель был архивирован {dataMisc.archiveDate ? formatDate(new Date(dataMisc.archiveDate * 1000)) : ''}
            </Typography>
            {dataMisc.archiveComment && <Typography sx={{ marginTop: '0.5em', whiteSpace: 'pre-wrap' }}>
              {dataMisc.archiveComment}
            </Typography>}
          </Box>}
          <InfoCard>
            <Stack direction='row' justifyContent='left' alignItems='center' sx={{ gap: '10px' }}>
              <AntSwitch disabled={disabled} checked={isOfficial} onChange={(event) => setIsOfficial(event.target.checked)} inputProps={{ 'aria-label': 'ant design' }} />
              <Typography>Водитель официально трудоустроен</Typography>
            </Stack>
          </InfoCard>
          <InfoCard>
            <Stack direction='row' justifyContent='left' alignItems='center' sx={{ gap: '10px' }}>
              <AntSwitch disabled={disabled} checked={data.consent ?? false} onChange={(event) => setData(prev => ({ ...prev, consent: event.target.checked }))} inputProps={{ 'aria-label': 'ant design' }} />
              <Typography>Перевозчик гарантирует, что получил необходимое согласие от водителя на обработку и передачу персональных данных</Typography>
            </Stack>
          </InfoCard>
          { isOfficial
            ? data.consent
              ? <>
                <InfoCard
                  title='Общая информация'
                >
                  <>
                    <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                      <TextField name='familyName' label='Фамилия' placeholder='Укажите фамилию водителя' disabled={disabled} width='33.33%' value={data.familyName} onChange={updateString('familyName')} errors={errorsData} />
                      <TextField name='firstName' label='Имя' placeholder='Укажите имя водителя' disabled={disabled} width='33.33%' value={data.firstName} onChange={updateString('firstName')} errors={errorsData} />
                      <TextField name='secondName' label='Отчество' placeholder='Укажите отчество водителя' disabled={disabled} width='33.33%' value={data.secondName} onChange={updateString('secondName')} errors={errorsData} />
                    </Stack>

                    <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
                      <TextField
                        name='email'
                        label='Email'
                        placeholder='Укажите адрес электронной почты водителя'
                        disabled={disabled}
                        width='33.33%'
                        value={data.email}
                        onChange={updateString('email')}
                        errors={errorsData}
                        maskParams={{
                          mask: EmailMask,
                          guide: false
                        }}
                      />
                      <TextField
                        name='phone'
                        label='Номер телефона'
                        placeholder='Укажите номер телефона водителя'
                        disabled={disabled}
                        width='33.33%'
                        value={data.phone}
                        onChange={updateString('phone')}
                        errors={errorsData}
                        maskParams={{
                          mask: PHONE_MASK
                        }}
                        errorMessage={doubleError ? 'Используется в системе' : undefined}
                      />
                      <TextField
                        name='permitNumber'
                        label='Номер водительского удостоверения'
                        placeholder='Укажите номер ВУ водителя'
                        disabled={disabled} width='33.33%'
                        value={data.permitNumber}
                        onChange={updateString('permitNumber')}
                        errors={errorsData}
                        maskParams={{
                          mask: [/\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
                        }}
                        errorMessage={doubleError ? 'Используется в системе' : undefined}
                      />
                    </Stack>
                  </>
                </InfoCard>
                <InfoCard title='Паспортные данные'>
                <>
                  <Stack direction='row' justifyContent='space-between' spacing={2} mb={2}>
                    <DatePicker
                      name='dateOfBirth'
                      width='15%'
                      disabled={disabled}
                      label={metaPassport.title('dateOfBirth')}
                      value={passport.dateOfBirth}
                      onChange={updatePassportDate('dateOfBirth')}
                      errors={errorsPassport}
                      shouldDisableDate={(day => dayjs().isBefore(day, 'day'))}
                    />
                    <TextField
                      width='15%'
                      disabled={disabled}
                      name='passportSeries'
                      label={metaPassport.title('passportSeries')}
                      value={passport.passportSeries}
                      onChange={updatePassportString('passportSeries')}
                      placeholder='11 22'
                      maskParams={{
                        mask: metaPassport.mask('passportSeries')
                      }}
                      errors={errorsPassport}
                    />
                    <TextField
                      width='15%'
                      disabled={disabled}
                      name='passportNum'
                      label={metaPassport.title('passportNum')}
                      value={passport.passportNum}
                      onChange={updatePassportString('passportNum')}
                      placeholder='123456'
                      maskParams={{
                        mask: metaPassport.mask('passportNum')
                      }}
                      errors={errorsPassport}
                    />
                    <TextField
                      width='55%'
                      disabled={disabled}
                      name='issuedDepartment'
                      label={metaPassport.title('issuedDepartment')}
                      value={passport.issuedDepartment}
                      onChange={updatePassportString('issuedDepartment')}
                      errors={errorsPassport}
                    />
                  </Stack>
                  <Stack direction='row' justifyContent='space-between' spacing={2}>
                    <DatePicker
                      name='dateIssue'
                      width='15%'
                      disabled={disabled}
                      label={metaPassport.title('dateIssue')}
                      value={passport.dateIssue}
                      onChange={updatePassportDate('dateIssue')}
                      errors={errorsPassport}
                      shouldDisableDate={(day => dayjs().isBefore(day, 'day'))}
                    />
                    <TextField
                      width='15%'
                      disabled={disabled}
                      name='departmentCode'
                      label={metaPassport.title('departmentCode')}
                      value={passport.departmentCode}
                      onChange={updatePassportString('departmentCode')}
                      placeholder='123'
                      maskParams={{
                        mask: PASSPORT_DEPARTAMENT_CODE_MASK
                      }}
                      errors={errorsPassport}
                    />
                    <AddressSuggestionsInput
                    width='71%'
                    disabled={disabled}
                    name='registrationAddress'
                    label={metaPassport.title('registrationAddress')}
                    value={passport.registrationAddress}
                    addressData={registrationAddressData}
                    onChange={(registrationAddress, addressData) => {
                      setPassport(prev => ({ ...prev, registrationAddress }))
                      setRegistrationAddressData(addressData)
                    }}
                    errors={errorsPassport}
                  />
                  </Stack>
                </>
              </InfoCard>
              <InfoCard
                title='Документы'
              >
                <>
                  {/* <Stack direction='row' justifyContent='space-between' alignItems='top' spacing={2} sx={{ mb: 2 }}>
                    <Upload
                      name='scanHireOrder'
                      disabled={disabled}
                      sx={{ width: '33.33%' }}
                      label='Приказ о приеме на работу'
                      value={data.scanHireOrder}
                      onChange={updateUpload('scanHireOrder')}
                      errors={errorsData}
                    />
                    <Upload
                      name='scanContract'
                      disabled={disabled}
                      sx={{ width: '33.33%' }}
                      label='Трудовой договор'
                      value={data.scanContract}
                      onChange={updateUpload('scanContract')}
                      errors={errorsData}
                    />
                  </Stack> */}

                  <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
                  <Upload
                      name='scanPermit'
                      disabled={disabled}
                      sx={{ width: '50%' }}
                      label='Водительское удостоверение'
                      value={data.scanPermit}
                      onChange={updateUpload('scanPermit')}
                      errors={errorsData}
                    />
                    <Upload
                      name='scanPassport1'
                      disabled={disabled}
                      sx={{ width: '50%' }}
                      label='Паспорт (разворот с фото)'
                      hint='В цветном формате'
                      value={data.scanPassport1}
                      onChange={updateUpload('scanPassport1')}
                      errors={errorsData}
                    />
                    <Upload
                      name='scanPassport2'
                      disabled={disabled}
                      sx={{ width: '50%' }}
                      label='Паспорт (прописка)'
                      hint='В цветном формате'
                      value={data.scanPassport2}
                      onChange={updateUpload('scanPassport2')}
                      errors={errorsData}
                    />
                  </Stack>
                </>
              </InfoCard>
              <InfoCard
                title='ТС, Прицеп'
              >
                <>
                  {dataMisc?.vehicleTrailer?.map(({ vehicleId, trailerId }, idx) =>
                    <VehicleTrailerRow
                      key={idx}
                      vehicles={vehicles}
                      trailers={trailers.filter(
                        trailer =>
                          !dataMisc?.vehicleTrailer?.filter(vt => vt.vehicleId === vehicleId)
                            .map(vt => vt.trailerId)
                            .includes(trailer.id) || trailer.id === trailerId
                      )}
                      vehicleId={vehicleId}
                      trailerId={trailerId}
                      disabled={vehicleTrailerDisabled}
                      onSelectVehicle={(vehicleId) => setDataMisc((data) => {
                        const { vehicleTrailer = [] } = data ?? {}

                        if (data === undefined || vehicleTrailer[idx] === undefined) {
                          return undefined
                        }

                        vehicleTrailer[idx].vehicleId = vehicleId

                        return { ...data, vehicleTrailer }
                      })}
                      onSelectTrailer={(trailerId) => setDataMisc((data) => {
                        const { vehicleTrailer = [] } = data ?? {}

                        if (data === undefined || vehicleTrailer[idx] === undefined) {
                          return undefined
                        }

                        vehicleTrailer[idx].trailerId = trailerId

                        return { ...data, vehicleTrailer }
                      })}
                      onDelete={() => setDataMisc((data) => {
                        const { vehicleTrailer = [] } = data ?? {}

                        if (data === undefined || vehicleTrailer[idx] === undefined) {
                          return undefined
                        }

                        vehicleTrailer.splice(idx, 1)

                        return { ...data, vehicleTrailer }
                      })}
                    />
                  )}
                  <VehicleTrailerRow
                    key='new'
                    vehicles={vehicles}
                    trailers={trailers}
                    onSelectVehicle={(vehicleId) => setDataMisc((data) => {
                      const { vehicleTrailer = [] } = data ?? {}

                      if (data === undefined) {
                        return undefined
                      }

                      vehicleTrailer.push({ vehicleId })

                      return { ...data, vehicleTrailer }
                    })}
                    disabled={vehicleTrailerDisabled}
                  />
                </>
              </InfoCard>
              </>
              : <Box sx={{
                mt: '10px',
                background: '#FFFAFA',
                border: '1px solid #EE6A5F',
                borderRadius: '8px',
                padding: '32px 24px'
              }}>
                  <Typography>
                  Для продолжения необходимо получить согласие от водителя на обработку и передачу персональных данных.
                  </Typography>
                </Box>
            : <Box sx={{
              mt: '10px',
              background: '#FFFAFA',
              border: '1px solid #EE6A5F',
              borderRadius: '8px',
              padding: '32px 24px'
            }}>
                <Typography>
                  Согласно законодательным требованиям, официальное трудоустройство водителя является обязательным условием сотрудничества.
                </Typography>
              </Box>
            }
          </div>
        </div>
        <Modal
          title='Предупреждение'
          open={confirmOpen}
          onClose={() => setConfirmOpen(false)}
          content={<Stack sx={{
            minWidth: '500px',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '21px',
            letterSpacing: '-0.02em',
            color: '#B2B2B2',
            px: 1
          }} >
            При подтверждении изменений водитель уйдет на модерацию.
          </Stack>}
          actions={<>
            <Button color='secondary' variant='outlined' onClick={() => setConfirmOpen(false)}>Отменить</Button>
            <Button variant='contained' onClick={ () => { save() } }>Отправить на рассмотрение</Button>
          </>}
        />
      </div>
  )
}
