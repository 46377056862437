import { MediaZipCreateParams } from 'api/media'
import metaAct from 'entity/Act'

export type Result = MediaZipCreateParams[] | undefined
export type Params = Partial<{
  filename: string
  registryFilename: string
  shortageFilename: string
  agreementFilename: string
  invoiceFilename: string
  finalBidFilename: string
}>

export default function actPack (data: Params | unknown): Result {
  if (data === null || typeof data !== 'object') {
    return
  }

  const { filename, registryFilename, shortageFilename, agreementFilename, invoiceFilename, finalBidFilename } = data as Params

  const pack = []

  finalBidFilename && pack.push({ filename: finalBidFilename, toName: metaAct.title('finalBidFilename') })
  filename && pack.push({ filename, toName: metaAct.title('filename') })

  registryFilename && pack.push({ filename: registryFilename, toName: metaAct.title('registryFilename') })
  shortageFilename && pack.push({ filename: shortageFilename, toName: metaAct.title('shortageFilename') })
  agreementFilename && pack.push({ filename: agreementFilename, toName: metaAct.title('agreementFilename') })
  invoiceFilename && pack.push({ filename: invoiceFilename, toName: metaAct.title('invoiceFilename') })

  return pack.length > 0 ? pack : undefined
}
