import { Edit } from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'
import { ProfilePosition } from 'api/profile'
import { useAuthContext } from 'AuthContext'
import { useState } from 'react'
import EditPositionsModal from 'ui/EditPositionsModal'
import InfoCard from 'ui/InfoCard'
import None from 'ui/None'
import SquareButton from 'ui/SquareButton'
import TsToFormatDate from 'util/TsToFormatDate'

export interface Params {
  positions: ProfilePosition[]
  onSuccess: () => void
  disabled?: boolean
}

export default function DispatcherPositions ({ positions, disabled, onSuccess }: Params) {
  const { profileRefresh } = useAuthContext()
  const [open, setOpen] = useState<ProfilePosition>()

  return (<>
    <InfoCard title='Должность в компании заказчика'>
      <Stack direction='column' gap={1}>
      { positions.length === 0 && <None desc='Список компаний заказчиков пуст' p={1} />}
      { positions.map((item) => {
        const { customer, isEmpty, willExpired, expired, position, startTs, endTs } = item
        return (<Stack
          key={customer.id}
          direction='row'
          gap={1}
          alignItems='center'
      >
        <Typography fontWeight={500}>{customer.fullName}</Typography>
        { !isEmpty
          ? <>
          <Typography>Должность: {position};</Typography>
          <Typography color={willExpired || expired ? '#ee6a5f' : undefined}>Действие доверенности c {TsToFormatDate(startTs, 'dd.MM.yyyy')} по {TsToFormatDate(endTs, 'dd.MM.yyyy')}</Typography>
          <SquareButton
            onClick={() => setOpen(item)}
            variant='outlined'
            disabled={disabled}
          ><Edit /></SquareButton>
        </>
          : <Button variant='contained' onClick={() => setOpen(item)} disabled={disabled}>Добавить информацию</Button> }
      </Stack>)
      }) }
      </Stack>
    </InfoCard>
    <EditPositionsModal
      position={open}
      onClose={() => setOpen(undefined)}
      onSuccess={() => {
        setOpen(undefined)
        profileRefresh()
        onSuccess()
      }}
    />
  </>)
}
