import { Meta } from './Meta'
import { Act } from 'api/act'

const act = new Meta<Partial<Act>>({
  filename: { title: 'УПД' },
  registryFilename: { title: 'Регистр' },
  shortageFilename: { title: 'Отчёт по недостаче' },
  agreementFilename: { title: 'Соглашение' },
  invoiceFilename: { title: 'Счет на оплату' },
  finalBidFilename: { title: 'Итоговая заявка' }
})

export default act
