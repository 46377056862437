import { Sync } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'
import { KisRequestHistory as Origin } from 'api/shipping'
import { kisRequestHistoryStatusMap, kisRequestHistoryTypeMap } from 'common/TruckRuns/kisRequestHistoryMap'
import KisRequestHistoryWizard from 'ui/KisRequestHistoryWizard'
import Tooltip from 'ui/Tooltip'

export interface Params {
  data: Origin[]
}

export default function KisRequestHistoryIcon ({ data }: Params) {
  return (<Box>
    <Tooltip
      title={<Stack direction='column' gap={0.5}>
        <Typography fontSize='14px' fontWeight={600} >История запросов КИС</Typography>
        {data.sort((a, b) => b.requestTs - a.requestTs).slice(0, 5).map(item => <KisRequestHistoryWizard
          data={item}
          typeMap={kisRequestHistoryTypeMap}
          statusMap={kisRequestHistoryStatusMap}
          key={`${item.requestTs}`}
        />)}
      </Stack>}
      arrow
      placement='top'
      view
    >
      <Sync color='secondary' sx={{ display: 'block' }} />
    </Tooltip>
  </Box>)
}
