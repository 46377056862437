import { ReactNode, useMemo } from 'react'
import { AddressSuggestions, DaDataAddress, DaDataSuggestion } from 'react-dadata'
import 'react-dadata/dist/react-dadata.css'
import clsx from 'clsx'
import './styles.sass'
import { Stack, Box } from '@mui/material'
import { ValidateError } from 'validation/validate'
import { AddressDetails } from 'api/AddressDetails'

export function getApiToken () {
  return process.env.REACT_APP_DADATA_API_TOKEN
}

export type AddressData = DaDataSuggestion<DaDataAddress & Partial<{
  room_type: string | null
  room_type_full: string | null
  room: string | null
  sub_area_with_type: string | null
  sub_area_type: string | null
  sub_area_type_full: string | null
  sub_area: string | null
}>>

export const getAddressDetails = ({
  data: {
    fias_id, kladr_id, postal_code, postal_box, country, country_iso_code, federal_district,
    region_iso_code, region_with_type, region_type, region_type_full, region, city_area,
    area_with_type, area_type, area_type_full, area,
    city_with_type, city_type, city_type_full, city,
    city_district_with_type, city_district_type, city_district_type_full, city_district,
    settlement_with_type, settlement_type, settlement_type_full, settlement,
    street_with_type, street_type, street_type_full, street,
    stead_type, stead_type_full, stead, house_type, house_type_full, house,
    block_type, block_type_full, block, flat_type, flat_type_full, flat,
    room_type, room_type_full, room, sub_area_type, sub_area_type_full, sub_area_with_type, sub_area
  }
}: AddressData): AddressDetails => ({
  fiasId: fias_id,
  kladrId: kladr_id,
  postalCode: postal_code ?? undefined,
  postalBox: postal_box ?? undefined,
  country,
  countryIsoCode: country_iso_code,
  federalDistrict: federal_district ?? undefined,
  cityArea: city_area ?? undefined,
  regionIsoCode: region_iso_code,
  regionWithType: region_with_type,
  regionType: region_type,
  regionTypeFull: region_type_full,
  region,
  areaWithType: area_with_type ?? undefined,
  areaType: area_type ?? undefined,
  areaTypeFull: area_type_full ?? undefined,
  area: area ?? undefined,
  cityWithType: city_with_type ?? undefined,
  cityType: city_type ?? undefined,
  cityTypeFull: city_type_full ?? undefined,
  city: city ?? undefined,
  cityDistrictWithType: city_district_with_type ?? undefined,
  cityDistrictType: city_district_type ?? undefined,
  cityDistrictTypeFull: city_district_type_full ?? undefined,
  cityDistrict: city_district ?? undefined,
  settlementWithType: settlement_with_type ?? undefined,
  settlementType: settlement_type ?? undefined,
  settlementTypeFull: settlement_type_full ?? undefined,
  settlement: settlement ?? undefined,
  streetWithType: street_with_type ?? undefined,
  streetType: street_type ?? undefined,
  streetTypeFull: street_type_full ?? undefined,
  street: street ?? undefined,
  steadType: stead_type ?? undefined,
  steadTypeFull: stead_type_full ?? undefined,
  stead: stead ?? undefined,
  houseType: house_type ?? undefined,
  houseTypeFull: house_type_full ?? undefined,
  house: house ?? undefined,
  blockType: block_type ?? undefined,
  blockTypeFull: block_type_full ?? undefined,
  block: block ?? undefined,
  flatType: flat_type ?? undefined,
  flatTypeFull: flat_type_full ?? undefined,
  flat: flat ?? undefined,
  roomType: room_type ?? undefined,
  roomTypeFull: room_type_full ?? undefined,
  room: room ?? undefined,
  subAreaWithType: sub_area_with_type ?? undefined,
  subAreaType: sub_area_type ?? undefined,
  subAreaTypeFull: sub_area_type_full ?? undefined,
  subArea: sub_area ?? undefined
})
export interface Params {
  label?: string
  hint?: ReactNode
  name?: string
  value?: string
  width?: string | number
  placeholder?: string
  errors?: ValidateError
  style?: React.CSSProperties
  errorMessage?: string
  disabled?: boolean
  styleContainer?: React.CSSProperties
  addressData?: AddressData
  onChange?: (value: string, addressData?: AddressData) => void
  onBlur?: () => void
}

const valueFormat = (origin?: AddressData): AddressData | undefined => {
  if (origin === undefined) {
    return
  }

  const { value, unrestricted_value, ...data } = origin
  return { value: unrestricted_value, unrestricted_value, ...data }
}

// Приоритет областей в запросе
const LOCATIONS_BOOST: { kladr_id: string }[] = [
  { kladr_id: '61' },
  { kladr_id: '23' }
]

export default function AddressSuggestionsInput ({
  value, hint, name, errorMessage, disabled,
  errors, styleContainer, width, label,
  placeholder, addressData, onChange, onBlur
}: Params) {
  const apiToken = getApiToken()

  const handleChange = (address?: AddressData) => {
    if (!onChange) {
      return
    }

    if (!address) {
      return
    }

    const { unrestricted_value, ...data } = address

    onChange(unrestricted_value, { unrestricted_value, ...data })
  }

  const eMessage = useMemo(() => {
    if (errorMessage !== undefined) {
      return errorMessage
    }

    const required = errors?.find(({ keyword, params }) => keyword === 'required' && params.missingProperty === name)

    if (required) {
      return 'Поле обязательно к заполнению'
    }

    const error = errors?.find(item => name && item.instancePath === `/${name}`)

    return error?.message
  }, [errors, name, errorMessage])

  return (
    <div className='TextField AddressSuggestionsInput' style={{ width, ...styleContainer }}>
      <Stack direction='row' justifyContent='space-between' sx={{
        fontWeight: '500',
        fontSize: '13px',
        lineHeight: '16px',
        letterSpacing: '-0.02em',
        whiteSpace: 'nowrap'
      }}>
        <Box sx={{ color: '#111111' }}>{label}</Box>
        { hint && <Box sx={{ color: '#B2B2B2' }}>{hint}</Box> }
      </Stack>
      { apiToken && <AddressSuggestions
        token={apiToken}
        value={valueFormat(addressData)}
        defaultQuery={value}
        onChange={handleChange}
        delay={100}
        autoload
        filterLocationsBoost={LOCATIONS_BOOST}

        inputProps={{
          onBlur,
          placeholder,
          className: clsx('TextField_field', { TextField_field_error: !!eMessage }),
          disabled,
          style: {
            width: '100%',
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '1rem',
            padding: '0px 12px',
            margin: '8px 0px',
            boxSizing: 'border-box',
            opacity: disabled ? 1 : undefined,
            WebkitTextFillColor: disabled ? 'rgba(0, 0, 0, 0.15)' : undefined
          }
        }}
      />}
      <div style={{ color: 'red', fontSize: '10px', marginTop: '-4px', height: '10px' }}>{eMessage}</div>
    </div>)
}
