import './styles.sass'
import { Id, isId } from 'api/Id'
import { DriverAllForUpdate, driverAllGet, DriverStatus, driverAllUpdate, VehicleTrailer, PassportForDriverCreate } from 'api/driver'
import { useAuthContext } from 'AuthContext'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useValidate from 'validation/validate'
import schema from 'validation/Driver'
import { useMainRoutes } from 'routes'
import NavigatePanel from 'ui/NavigatePanel'
import { Button, Stack, Typography, Box } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import InfoCard from 'ui/InfoCard'
import TextField from 'ui/TextField'
import { personName } from 'util/personName'
import AntSwitch from 'ui/AntSwitch'
import Upload from 'ui/Upload'
import formatDate from 'util/FormatDate'
import { phoneClean, PHONE_MASK } from 'common/Mask/PhoneMask'
import DatePicker from 'ui/DatePicker'
import metaPassport from 'entity/Passport'
import { passportAllUpdate } from 'api/passport'
import schemaPassport, { clear as clearPassportData } from 'validation/Passport'
import dayjs from 'dayjs'
import useTabs from 'common/Registry/Carrier/useTabs'
import { ProfileAll, profileAllGet } from 'api/profile'
import { UserRole } from 'api/user'
import Alert from 'ui/Alert'
import Confirm from 'ui/Confirm'
import RejectionModal from 'ui/RejectionModal'
import { rejectionCreate } from 'api/rejection'
import useDisabled from 'common/Registry/Carrier/useDisabled'
import { BlackListEntry, BlackListStatus } from 'api/black-list'
import InfoCell, { InfoCellProps } from 'ui/InfoCell'
import { vehicleAllList, VehicleAll } from 'api/vehicle'
import { trailerAllList, TrailerAll } from 'api/trailer'
import Select from 'ui/Select'
import SquareButton from 'ui/SquareButton'
import { DeleteForever } from '@mui/icons-material'
import History from 'ui/History'
import { EmailMask } from 'common/Mask/EmailMask'
import { ChipDriverStatusDispatcher } from 'common/ChipDriverStatusDispatcher'
import { PASSPORT_DEPARTAMENT_CODE_MASK } from 'common/Mask/Passport'
import onlyFields from 'util/onlyFields'
import DownloadZipButton, { slug } from 'ui/DownloadZipButton'
import driverPack, { Result as Pack } from 'common/map/driverPack'
import RegistryCarrierLink from 'ui/RegistryCarrierLink'
import vehicleName from 'util/vehicleName'
import trailerName from 'util/trailerName'
import AddressSuggestionsInput, { AddressData, getAddressDetails } from 'ui/AddressSuggestionsInput'

type UpdateParams = Omit<DriverAllForUpdate, 'id'>
type Passport = Partial<PassportForDriverCreate>
interface DataMisc {
  status: DriverStatus
  archiveDate?: number
  archiveComment?: string
  truckRuns?: Id[]
  blackList?: BlackListEntry[]
  vehicleTrailer?: VehicleTrailer[]
}

type VehicleTrailerOptions = Partial<VehicleTrailer> & {
  onSelectVehicle: (vehicleId: Id) => void
  onSelectTrailer?: (trailerId?: Id) => void
  onDelete?: () => void
  vehicles: VehicleAll[],
  trailers: TrailerAll[]
}

function VehicleTrailerRow ({
  vehicleId,
  trailerId,
  onSelectVehicle,
  onSelectTrailer,
  onDelete,
  vehicles,
  trailers
}: VehicleTrailerOptions) {
  return <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
    <Select
      label='ТС'
      placeholder='Выберите ТС'
      options={vehicles.map(({ id, brand, model, ...doc }) => ({
        value: id,
        name: `${vehicleName(doc, 'short')} - ${brand} ${model}`
      }))}
      value={vehicleId}
      onChange={onSelectVehicle}
      disableClearable={true}
      width='50%'
    />
    {onSelectTrailer && <Select
      label='Прицеп'
      placeholder='Без прицепа'
      options={trailers.map(({ id, model, ...doc }) => ({
        value: id,
        name: `${trailerName(doc)} - ${model}`
      }))}
      value={trailerId}
      onChange={onSelectTrailer}
      width='50%'
    />}
    {onDelete && <SquareButton
      variant='contained'
      color='error'
      widthHeight='40px'
      onClick={onDelete}
    >
      <DeleteForever style={{ color: '#ffffff' }}/>
    </SquareButton>}
  </Stack>
}

const BlackListStatusText = ({ status }: {status: BlackListStatus}) => {
  if (status === BlackListStatus.ban) {
    return <>Полный запрет на въезд</>
  } else if (status === BlackListStatus.control) {
    return <>На особом контроле (без запрета на въезд)</>
  }

  return <></>
}

const BlackListItem = ({ data }: { data: BlackListEntry }) => {
  const cells: InfoCellProps[] = [
    { label: 'Номер ВУ', value: data.object },
    { label: 'Статус', value: <BlackListStatusText status={data.status} /> },
    { label: 'Описание', value: data.description }
  ]

  return <InfoCard>
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      color: '#111'
    }}>
      { cells.map((item, idx) => <InfoCell {...item} key={idx} />) }
    </Box>
  </InfoCard>
}

export default function CarrierDriver () {
  const { carrier: profileId, driverId: id } = useParams()
  const { handleResponseFailure, handleResponseSuccess, currentInterface } = useAuthContext()

  const [data, setData] = useState<UpdateParams>()
  const [vehicles, setVehicles] = useState<VehicleAll[]>([])
  const [trailers, setTrailers] = useState<TrailerAll[]>([])
  const [disabled, setDisabled] = useState<boolean>(useDisabled(currentInterface).disabled)
  const [dataMisc, setDataMisc] = useState<DataMisc>()
  const [passport, setPassport] = useState<Passport>({})
  const [passportId, setPassportId] = useState<Id>()
  const [profile, setProfile] = useState<ProfileAll>()
  const [rejectionModalOpen, setRejectionModalOpen] = useState<boolean>(false)
  const [pack, setPack] = useState<Pack>()
  const [registrationAddressData, setRegistrationAddressData] = useState<AddressData>()

  const navigate = useNavigate()
  const { check: checkData, errors: errorsData } = useValidate(schema)
  const { check: checkPassport, errors: errorsPassport } = useValidate(schemaPassport)
  const { links, routesMap } = useMainRoutes()

  const { tabs } = useTabs(isId(profileId) ? profileId : undefined, isId(id) ? id : undefined)

  const clear = (data?: UpdateParams): UpdateParams | undefined => {
    if (data === undefined) {
      return undefined
    }
    const { permitNumber, phone, secondName } = data
    return {
      ...data,
      secondName: secondName || '',
      permitNumber: permitNumber === undefined ? undefined : permitNumber.replace(/\D/g, ''),
      phone: phone === undefined ? undefined : phoneClean(phone)
    }
  }

  const savePassport = useCallback(async () => {
    const clearData = clearPassportData(passport)

    if (!checkPassport(clearData)) {
      return false
    }

    if (isId(passportId)) {
      return await passportAllUpdate({
        id: passportId,
        ...clearData,
        registrationAddressDetails: registrationAddressData ? getAddressDetails(registrationAddressData) : undefined
      })
    }
  }, [passport, checkPassport, passportId, registrationAddressData])

  const save = useCallback(async () => {
    const clearData = clear(data)

    if (!checkData(clearData)) {
      return
    }

    if (isId(id)) {
      const resultData = await driverAllUpdate({ id, ...clearData, vehicleTrailer: dataMisc?.vehicleTrailer })
      const resultPassport = await savePassport()

      if (resultData || resultPassport) {
        handleResponseSuccess('Данные водителя изменены')
        navigate(-1)
      }
    }
  }, [data, checkData, id, dataMisc?.vehicleTrailer, savePassport, handleResponseSuccess, navigate])

  const init = useCallback(async () => {
    const profile = isId(profileId) ? await profileAllGet(profileId, { role: UserRole.carrier }) : null

    if (profile === null) {
      handleResponseFailure('Перевозчик не найден')
      return
    }

    setProfile(profile)

    const result = isId(id) ? await driverAllGet(id) : null

    if (result !== null) {
      const { id, owner, status, archiveDate, passport, createTs, archiveComment, truckRuns = [], blackList = [], vehicleTrailer = [], isCarrier, ...data } = result

      const onlyChangeableFields = onlyFields(data,
        'familyName', 'firstName', 'secondName',
        'phone', 'email', 'consent', 'permitNumber',
        'scanPermit', 'scanPassport1', 'scanPassport2',
        'scanHireOrder', 'scanContract')
      setDataMisc({ status, archiveDate, archiveComment, truckRuns, blackList, vehicleTrailer })
      setDisabled((disabled) => disabled || status === DriverStatus.archive)
      setData(onlyChangeableFields)

      if (passport !== null) {
        const { id: pId, status: pStatus, registrationAddressDetails, ...passportData } = passport
        setPassportId(pId)
        setPassport(passportData)
      }

      vehicleAllList({ owner }).then((result) => setVehicles(result))
      trailerAllList({ owner }).then((result) => setTrailers(result))
      setPack(driverPack(data))
    } else {
      handleResponseFailure('Водитель не найден')
    }
  }, [handleResponseFailure, id, profileId])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { init() }, [])

  const changeDriverStatus = useCallback((status: DriverStatus): void => {
    if (isId(id)) {
      driverAllUpdate({ id, status }).then(result => {
        if (result === null) {
          handleResponseFailure('Произошла ошибка')
        } else {
          handleResponseSuccess('Статус водителя изменен')
          navigate(-1)
        }
      })
    }
  }, [handleResponseFailure, handleResponseSuccess, id, navigate])

  const handleRejection = (reasonSlug: string, comment?: string) => {
    if (data && isId(id)) {
      rejectionCreate({ objectId: id, reasonSlug, comment })
        .then(result => {
          if (result) {
            changeDriverStatus(DriverStatus.blocked)
          }
        })
    }
  }

  const updateString = (key: keyof UpdateParams) => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      const { target: { value } } = event
      setData({ ...data, [key]: value === '' ? undefined : value })
    }
  }

  const updateUpload = (key: string) => {
    return (filename: string | undefined) => {
      setData({ ...data, [key]: filename })
    }
  }

  const presentation = (data: UpdateParams) => personName(data)

  const updatePassportString = (key: keyof Passport) => (e: ChangeEvent<HTMLInputElement>) => {
    setPassport(prev => ({ ...prev, [key]: e.target.value }))
  }

  const updatePassportDate = (key: keyof Passport) => (value: number) => {
    setPassport(prev => ({ ...prev, [key]: value }))
  }

  if (profile === undefined || data === undefined) {
    return <></>
  }

  return (<>
    <RejectionModal
      open={rejectionModalOpen}
      onCancel={() => setRejectionModalOpen(false)}
      onReject={handleRejection}
    />
    <div className='registryCarriers_driver'>
      <NavigatePanel
        title={
          <Box sx={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
              <Box>{presentation(data)}</Box>
              { dataMisc
                ? <ChipDriverStatusDispatcher status={dataMisc.status} truckRuns={dataMisc.truckRuns}/>
                : <></>
              }
          </Box>
        }
        tabs={tabs}
        breadcrumbs={{
          items: [
            {
              title: profile.organization?.shortName || personName(profile, profile.phone),
              to: `${links.REGISTRY_CARRIER_PAGE}/${profile.id}`
            },
            {
              title: 'Водители',
              to: `${links.REGISTRY_CARRIER_PAGE}/${profile.id}/drivers`
            },
            {
              title: personName(data)
            }
          ],
          defaultItems: routesMap.getBreadcrumbs(links.REGISTRY_CARRIERS_PAGE)
        }}
        actions={
          <Stack direction='row' spacing={2} justifyContent='end'>
            { pack && <DownloadZipButton pack={pack} archiveName={slug(`водитель_${personName(data)}`)} /> }
            <Button variant='outlined' color='secondary' size='small' onClick={() => navigate(-1)}>
              { disabled ? 'Закрыть' : 'Отменить' }
            </Button>
            { disabled
              ? <></>
              : <Button variant='contained' color='success' size='small' onClick={save}>
                Сохранить <SaveIcon sx={{ width: '15px', height: '15px', ml: '10px' }}/>
              </Button>
            }
          </Stack>
        }
      />
      <div className='registryCarriers_driver__body'>
        <div className='registryCarriers_driver__content'>
          <RegistryCarrierLink profile={profile} navigate={navigate} />
          { (dataMisc?.status === DriverStatus.new && !disabled) && <Confirm
            title='Подтверждение информации'
            text={<>
              Перевозчик добавил нового водителя и ожидает его подтверждения.<br/>
              Проверьте указанную информацию, загруженные фотокопии документов и соответствие данных между ними.
            </>}
            onAllow={() => changeDriverStatus(DriverStatus.active)}
            onReject={() => setRejectionModalOpen(true)}
          />}
          { !disabled && dataMisc?.status === DriverStatus.blocked && <Alert
              color='#EE6A5F'
              controls={<Button
                variant='contained'
                color='inherit'
                sx={{
                  background: '#F49E97',
                  color: '#FFFFFF',
                  '&:hover': {
                    background: '#F49E97',
                    opacity: '0.9'
                  }
                }}
                onClick={() => changeDriverStatus(DriverStatus.new)}
              >Пересмотреть отказ</Button>}
            >
            <>
              <Typography fontWeight={600} fontSize='18px' color='#ffffff' sx={{ mb: 1 }}>Отказано в добавлении водителя</Typography>
              <Typography fontWeight={400} fontSize='14px' color='#ffffff'>Причина отказа: {data?.rejection?.reason.name}</Typography>
            </>
          </Alert>}
          { disabled && dataMisc?.status === DriverStatus.blocked && <Box sx={{
            mt: '10px',
            background: '#EE6A5F',
            borderRadius: '8px',
            padding: '32px 24px',
            color: '#fff'
          }}>
            <Typography sx={{
              fontWeight: '600',
              fontSize: '18px',
              lineHeight: '22px',
              letterSpacing: '-0.02em',
              mb: 1
            }}>
              Отказано в добавлении водителя
            </Typography>
            <Typography>
              К сожалению, добавление Водителя с указанными вами данными невозможно добавить в систему.
            </Typography>
            <Typography>
              Дополнительную информацию вы можете получить по адресу <a href='mailto: info@aston.ru' style={{ color: '#fff' }}>info@aston.ru</a>
            </Typography>
          </Box>}
          { dataMisc && dataMisc.blackList && dataMisc.blackList.length > 0 && <Box sx={{
            mt: '10px',
            background: '#EE6A5F',
            borderRadius: '8px',
            padding: '32px 24px',
            color: '#fff'
          }}>
            <Typography sx={{
              fontWeight: '600',
              fontSize: '18px',
              lineHeight: '22px',
              letterSpacing: '-0.02em',
              mb: 1
            }}>
              Найденные записи в блек-листе
            </Typography>
            <Stack>
              {dataMisc.blackList.map((data) => <BlackListItem data={data} />)}
            </Stack>
          </Box>}
          {dataMisc?.status === DriverStatus.archive && <Box sx={{
            mt: '10px',
            background: '#EBEBEB',
            borderRadius: '8px',
            padding: '32px 24px',
            color: '#111'
          }}>
            <Typography sx={{
              fontWeight: '600',
              fontSize: '18px',
              lineHeight: '22px',
              letterSpacing: '-0.02em'
            }}>
              Водитель был архивирован {dataMisc.archiveDate ? formatDate(new Date(dataMisc.archiveDate * 1000)) : ''}
            </Typography>
            {dataMisc.archiveComment && <Typography sx={{ marginTop: '0.5em', whiteSpace: 'pre-wrap' }}>
              {dataMisc.archiveComment}
            </Typography>}
          </Box>}
          <InfoCard>
            <Stack direction='row' justifyContent='left' alignItems='center' sx={{ gap: '10px' }}>
              <AntSwitch disabled={true} checked={data.consent ?? false} onChange={(event) => setData(prev => ({ ...prev, consent: event.target.checked }))} inputProps={{ 'aria-label': 'ant design' }} />
              <Typography>Перевозчик гарантирует, что получил необходимое согласие от водителя на обработку и передачу персональных данных</Typography>
            </Stack>
          </InfoCard>
          { data.consent
            ? <>
              <InfoCard
                title='Общая информация'
              >
                <>
                  <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                    <TextField name='familyName' label='Фамилия' placeholder='Укажите фамилию водителя' disabled={disabled} width='33.33%' value={data.familyName} onChange={updateString('familyName')} errors={errorsData} />
                    <TextField name='firstName' label='Имя' placeholder='Укажите имя водителя' disabled={disabled} width='33.33%' value={data.firstName} onChange={updateString('firstName')} errors={errorsData} />
                    <TextField name='secondName' label='Отчество' placeholder='Укажите отчество водителя' disabled={disabled} width='33.33%' value={data.secondName} onChange={updateString('secondName')} errors={errorsData} />
                  </Stack>

                  <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
                    <TextField
                      name='email'
                      label='Email'
                      placeholder='Укажите адрес электронной почты водителя'
                      disabled={disabled}
                      width='33.33%'
                      value={data.email}
                      onChange={updateString('email')}
                      errors={errorsData}
                      maskParams={{
                        mask: EmailMask,
                        guide: false
                      }}
                    />
                    <TextField
                      name='phone'
                      label='Номер телефона'
                      placeholder='Укажите номер телефона водителя'
                      disabled={disabled}
                      width='33.33%'
                      value={data.phone}
                      onChange={updateString('phone')}
                      errors={errorsData}
                      maskParams={{
                        mask: PHONE_MASK
                      }}
                    />
                    <TextField
                      name='permitNumber'
                      label='Номер водительского удостоверения'
                      placeholder='Укажите номер ВУ водителя'
                      disabled={disabled} width='33.33%'
                      value={data.permitNumber}
                      onChange={updateString('permitNumber')}
                      errors={errorsData}
                      maskParams={{
                        mask: [/\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
                      }}
                    />
                  </Stack>
                </>
              </InfoCard>
              <InfoCard title='Паспортные данные'>
              <>
                <Stack direction='row' justifyContent='space-between' spacing={2} mb={2}>
                  <DatePicker
                    name='dateOfBirth'
                    width='15%'
                    disabled={disabled}
                    label={metaPassport.title('dateOfBirth')}
                    value={passport.dateOfBirth}
                    onChange={updatePassportDate('dateOfBirth')}
                    errors={errorsPassport}
                    shouldDisableDate={(day => dayjs().isBefore(day, 'day'))}
                  />
                  <TextField
                    width='15%'
                    disabled={disabled}
                    name='passportSeries'
                    label={metaPassport.title('passportSeries')}
                    value={passport.passportSeries}
                    onChange={updatePassportString('passportSeries')}
                    placeholder='11 22'
                    maskParams={{
                      mask: metaPassport.mask('passportSeries')
                    }}
                    errors={errorsPassport}
                  />
                  <TextField
                    width='15%'
                    disabled={disabled}
                    name='passportNum'
                    label={metaPassport.title('passportNum')}
                    value={passport.passportNum}
                    onChange={updatePassportString('passportNum')}
                    placeholder='123456'
                    maskParams={{
                      mask: metaPassport.mask('passportNum')
                    }}
                    errors={errorsPassport}
                  />
                  <TextField
                    width='55%'
                    disabled={disabled}
                    name='issuedDepartment'
                    label={metaPassport.title('issuedDepartment')}
                    value={passport.issuedDepartment}
                    onChange={updatePassportString('issuedDepartment')}
                    errors={errorsPassport}
                  />
                </Stack>
                <Stack direction='row' justifyContent='space-between' spacing={2}>
                  <DatePicker
                    name='dateIssue'
                    width='15%'
                    disabled={disabled}
                    label={metaPassport.title('dateIssue')}
                    value={passport.dateIssue}
                    onChange={updatePassportDate('dateIssue')}
                    errors={errorsPassport}
                    shouldDisableDate={(day => dayjs().isBefore(day, 'day'))}
                  />
                  <TextField
                    width='15%'
                    disabled={disabled}
                    name='departmentCode'
                    label={metaPassport.title('departmentCode')}
                    value={passport.departmentCode}
                    onChange={updatePassportString('departmentCode')}
                    placeholder='123'
                    maskParams={{
                      mask: PASSPORT_DEPARTAMENT_CODE_MASK
                    }}
                    errors={errorsPassport}
                  />
                  <AddressSuggestionsInput
                    width='71%'
                    disabled={disabled}
                    name='registrationAddress'
                    label={metaPassport.title('registrationAddress')}
                    value={passport.registrationAddress}
                    addressData={registrationAddressData}
                    onChange={(registrationAddress, addressData) => {
                      setPassport(prev => ({ ...prev, registrationAddress }))
                      setRegistrationAddressData(addressData)
                    }}
                    errors={errorsPassport}
                  />
                </Stack>
              </>
            </InfoCard>
              <InfoCard
                title='Документы'
              >
                <>
                  {/* <Stack direction='row' justifyContent='space-between' alignItems='top' spacing={2} sx={{ mb: 2 }}>
                    <Upload
                      name='scanHireOrder'
                      disabled={disabled}
                      sx={{ width: '33.33%' }}
                      label='Приказ о приеме на работу'
                      value={data.scanHireOrder}
                      onChange={updateUpload('scanHireOrder')}
                      errors={errorsData}
                    />
                    <Upload
                      name='scanContract'
                      disabled={disabled}
                      sx={{ width: '33.33%' }}
                      label='Трудовой договор'
                      value={data.scanContract}
                      onChange={updateUpload('scanContract')}
                      errors={errorsData}
                    />
                  </Stack> */}
                  <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
                    <Upload
                      name='scanPassport1'
                      disabled={disabled}
                      sx={{ width: '50%' }}
                      label='Паспорт (разворот с фото)'
                      hint='В цветном формате'
                      value={data.scanPassport1}
                      onChange={updateUpload('scanPassport1')}
                      errors={errorsData}
                    />
                    <Upload
                      name='scanPassport2'
                      disabled={disabled}
                      sx={{ width: '50%' }}
                      label='Паспорт (прописка)'
                      hint='В цветном формате'
                      value={data.scanPassport2}
                      onChange={updateUpload('scanPassport2')}
                      errors={errorsData}
                    />
                    <Upload
                      name='scanPermit'
                      disabled={disabled}
                      sx={{ width: '50%' }}
                      label='Водительское удостоверение'
                      value={data.scanPermit}
                      onChange={updateUpload('scanPermit')}
                      errors={errorsData}
                    />
                  </Stack>
                </>
              </InfoCard>
              { !disabled && <InfoCard
                title='ТС, Прицеп'
              >
                <>
                  {dataMisc?.vehicleTrailer?.map(({ vehicleId, trailerId }, idx) =>
                    <VehicleTrailerRow
                      key={idx}
                      vehicles={vehicles}
                      trailers={trailers}
                      vehicleId={vehicleId}
                      trailerId={trailerId}
                      onSelectVehicle={(vehicleId) => setDataMisc((data) => {
                        const { vehicleTrailer = [] } = data ?? {}

                        if (data === undefined || vehicleTrailer[idx] === undefined) {
                          return undefined
                        }

                        vehicleTrailer[idx].vehicleId = vehicleId

                        return { ...data, vehicleTrailer }
                      })}
                      onSelectTrailer={(trailerId) => setDataMisc((data) => {
                        const { vehicleTrailer = [] } = data ?? {}

                        if (data === undefined || vehicleTrailer[idx] === undefined) {
                          return undefined
                        }

                        vehicleTrailer[idx].trailerId = trailerId

                        return { ...data, vehicleTrailer }
                      })}
                      onDelete={() => setDataMisc((data) => {
                        const { vehicleTrailer = [] } = data ?? {}

                        if (data === undefined || vehicleTrailer[idx] === undefined) {
                          return undefined
                        }

                        vehicleTrailer.splice(idx, 1)

                        return { ...data, vehicleTrailer }
                      })}
                    />
                  )}
                  <VehicleTrailerRow
                    key='new'
                    vehicles={vehicles}
                    trailers={trailers}
                    onSelectVehicle={(vehicleId) => setDataMisc((data) => {
                      const { vehicleTrailer = [] } = data ?? {}

                      if (data === undefined) {
                        return undefined
                      }

                      vehicleTrailer.push({ vehicleId })

                      return { ...data, vehicleTrailer }
                    })}
                  />
                </>
              </InfoCard>}
              { !disabled && isId(id) && <InfoCard
                title='История изменений'
              >
                <History filter={{
                  col: ['main.drivers'],
                  id: [id]
                }} onUndo={init}/>
              </InfoCard>
              }
            </>
            : <Box sx={{
              mt: '10px',
              background: '#FFFAFA',
              border: '1px solid #EE6A5F',
              borderRadius: '8px',
              padding: '32px 24px'
            }}>
                <Typography>
                Для продолжения необходимо получить согласие от водителя на обработку и передачу персональных данных.
                </Typography>
              </Box>
            }
          </div>
        </div>
      </div>
  </>)
}
